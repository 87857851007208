<template>
  <div>
    <el-card >
      <template #header >
        <el-button type="primary" size="small" plain style="float: left" @click="editComplete()" v-if="sourceType==='local'" :icon="backIcon">Back To Quote Page</el-button>
        <el-button-group v-if="sourceType==='normal'||sourceType==='local'">
          <el-button type="primary" size="small" @click="rcEdit()" style="margin-right: 30px">{{$t('msg.rate.rcEdit')}}</el-button>
          <el-button type="primary" size="small" @click="editComplete()" v-if="sourceType!=='local'&&sourceType!=='view'">{{$t('msg.rate.editComplete')}}</el-button>
        </el-button-group>
      </template>
      <el-table :data="drayRate" border>
        <el-table-column prop="item" :label="$t('msg.rate.item')" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.item" placeholder="Cost Category" size="small" Style="font-size: small" @change="getC1(scope.$index)" v-if="scope.$index+myFlag===-1">
              <el-option label="Criteria" value="Criteria"></el-option>
              <el-option label="Cost Plus Service Fee" value="Cost Plus Service Fee"></el-option>
              <el-option label="Drayage" value="Drayage"></el-option>
              <el-option label="Pass Through" value="Pass Through"></el-option>
              <el-option label="Std Ad Hoc" value="Std Ad Hoc"></el-option>
            </el-select>
            <span v-else>{{scope.row.item}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="c1" :label="$t('msg.rate.c1')" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.c1" size="small" Style="font-size: small" @change="handleC1Change(scope.$index)" v-if="scope.$index+myFlag===-1 && scope.row.item!=='Drayage'">
              <el-option v-for="item in chargeCode" :key="item.c1" :label="item.c1" :value="item.c1" />
            </el-select>
            <span v-else>{{scope.row.c1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="c2" :label="$t('msg.rate.c2')" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.c2" size="small" controls-position="right" :step="1" v-if="scope.$index+myFlag===-1"></el-input>
            <span v-else>{{scope.row.c2}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ratePerUOM" :label="$t('msg.rate.ratePerUOM')" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.ratePerUOM" size="small" controls-position="right" :step="1" v-if="scope.$index+myFlag===-1"></el-input-number>
            <span v-else-if="scope.row.item==='Criteria'">{{scope.row.ratePerUOM}}</span>
            <span v-else>$ {{scope.row.ratePerUOM}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="adjust" :label="$t('msg.rate.adjust')" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.adjust" size="small" controls-position="right" :step="1" v-if="scope.$index+myFlag===-1"></el-input-number>
            <span v-else-if="scope.row.item==='Criteria'">{{scope.row.adjust}}</span>
            <span v-else-if="scope.row.adjust===-1">{{scope.row.adjust}}</span>
            <span v-else>$ {{scope.row.adjust}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="uom" :label="$t('msg.rate.uom')" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.uom" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>
            <span v-else>{{scope.row.uom}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="notes" :label="$t('msg.rate.notes')" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.notes" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>
            <span v-else>{{scope.row.notes}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px" align="center" v-if="rcEditVisible">
          <template #default="scope">
            <el-button @click="toEditable(scope.$index)" v-if="scope.$index+myFlag!==-1" :icon="editIcon"/>
            <el-button type="primary" @click="addRow(scope.$index)" v-if="scope.$index+myFlag!==-1" :icon="addIcon" />
            <el-button type="danger" @click="delHandel(scope.$index)" v-if="scope.$index+myFlag!==-1" :icon="delIcon" />
            <el-button type="success" size="small" @click="confirmUpdate(scope.$index)" plain v-if="scope.$index+myFlag===-1">Confirm</el-button>
            <el-button type="danger" size="small" @click="cancelUpdate()" plain v-if="scope.$index+myFlag===-1">Cancel</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { Edit, Delete, DocumentAdd, Back } from '@element-plus/icons-vue'

import axios from 'axios'
export default defineComponent({
  name: 'drayRCEdit',
  props: {
    myParam: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const sourceType = ref('normal')
    const drayRate = ref([{ item: '', c1: '', c2: '', ratePerUOM: 0, adjust: 0, uom: '', notes: '' }])
    const chargeCode = ref([{ item: '', c1: '', c2: '', ratePerUOM: 0, adjust: 0, uom: '', notes: '' }])
    const myFlag = ref(0)
    const allChargeCode = ref([])
    const rcEditVisible = ref(false)
    const editIcon = Edit
    const delIcon = Delete
    const addIcon = DocumentAdd
    const backIcon = Back
    const rcEdit = () => {
      getChargeCode()
      rcEditVisible.value = true
    }
    const editComplete = () => {
      rcEditVisible.value = false
      allChargeCode.value = []
      drayRate.value = []
      if (sourceType.value === 'local') {
        ctx.emit('edit-complete', props.myParam.split(';')[1])
      } else {
        window.close()
      }
    }
    const toEditable = (id) => {
      if (myFlag.value < 0) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      myFlag.value = -(id + 1)
    }
    const addRow = (id) => {
      if (myFlag.value < 0) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      drayRate.value.splice(id + 1, 0, { item: '', c1: '', c2: '', ratePerUOM: 0, adjust: 0, uom: '', notes: '' })
      myFlag.value = -(id + 2)
    }
    const delHandel = (id) => {
      if (myFlag.value < 0) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      drayRate.value.splice(id, 1)
      handleUpdate()
    }
    const getChargeCode = () => {
      const reqURL = process.env.VUE_APP_BASE + '/getDrayChargeCode'
      axios.get(reqURL).then((response) => {
        chargeCode.value = response.data
        localStorage.setItem('allChargeCode', JSON.stringify(response.data))
      }).catch((response) => {
        console.log(response)
      })
    }
    const getC1 = (id) => {
      allChargeCode.value = JSON.parse(localStorage.getItem('allChargeCode'))
      const selectedRemark = []
      const myRemark = drayRate.value[id].item
      for (let n = 0; n < allChargeCode.value.length; n++) {
        if (allChargeCode.value[n].item === myRemark) {
          selectedRemark.push(allChargeCode.value[n])
        }
      }
      chargeCode.value = selectedRemark
      drayRate.value[id] = selectedRemark[0]
    }
    const handleC1Change = (id) => {
      const myC1 = drayRate.value[id].c1
      for (let n = 0; n < chargeCode.value.length; n++) {
        if (chargeCode.value[n].c1 === myC1) {
          drayRate.value[id] = chargeCode.value[n]
          return
        }
      }
    }
    const handleUpdate = () => {
      const strList = props.myParam.split(';')
      const rcSource = strList[0]
      if (rcSource === 'local') {
        localStorage.setItem(strList[1], JSON.stringify(drayRate.value))
      } else {
        const param = strList[1] + '&newRate=' + JSON.stringify(drayRate.value)
        const rcURL = rcSource === 'spot' ? '/drayRC/updateSpotRC' : '/drayRC/updateRegularRC'
        const reqURL = process.env.VUE_APP_BASE + rcURL
        axios.post(reqURL, param).then((response) => {
          drayRate.value = response.data
        }).catch((response) => {
          console.log(response)
        })
      }
      myFlag.value = 0
    }
    const confirmUpdate = (id) => {
      const myRemark = drayRate.value[id].item
      if (myRemark === 'Drayage') {
        const key = drayRate.value[id].c2
        let t = 0
        for (let n = 0; n < drayRate.value.length; n++) {
          if (drayRate.value[n].c2 === key) {
            t++
            if (t >= 2) {
              alert('Update is invalid because of destination duplication!')
              return false
            }
          }
        }
      } else {
        const key = drayRate.value[id].c1
        let t = 0
        for (let n = 0; n < drayRate.value.length; n++) {
          if (drayRate.value[n].c1 === key && drayRate.value[n].c2 === drayRate.value[id].c2) {
            t++
            if (t >= 2) {
              alert('Update is invalid because of factor1 duplication!')
              return false
            }
          }
        }
      }
      handleUpdate()
    }
    const cancelUpdate = () => {
      myFlag.value = 0
      getDrayRC()
    }
    const getRCFromLocal = (storeTag) => {
      drayRate.value = JSON.parse(localStorage.getItem(storeTag))
    }
    const getDrayRC = () => {
      const strList = props.myParam.split(';')
      const rcSource = strList[0]
      const param = strList[1]
      if (rcSource === 'local') {
        sourceType.value = rcSource
        getRCFromLocal(param)
      } else {
        const rcURL = rcSource === 'spot' ? '/drayRC/getSpotRC' : '/drayRC/getRegularRC'
        if (strList.length >= 3) {
          sourceType.value = strList[2]
        }
        const reqURL = process.env.VUE_APP_BASE + rcURL
        axios.post(reqURL, param).then((response) => {
          drayRate.value = response.data
        }).catch((response) => {
          console.log(response.data)
        })
      }
    }
    onMounted(() => {
      getDrayRC()
    })
    watch(() => props.myParam, () => { getDrayRC() })
    return { sourceType, addIcon, delIcon, editIcon, backIcon, myFlag, rcEditVisible, drayRate, chargeCode, getRCFromLocal, confirmUpdate, cancelUpdate, getC1, handleC1Change, getChargeCode, editComplete, rcEdit, toEditable, addRow, delHandel }
  }
})
</script>
